import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@material-ui/styles";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import { LinkRouter } from "../../components";
import Button from "@material-ui/core/Button";
import Card from "@material-ui/core/Card";
import { UserService } from "../../service";
import validate from 'validate.js';
import { history, i18n, theme } from "../../config";
import Alert from "@material-ui/lab/Alert";
import Snackbar from "@material-ui/core/Snackbar";
import Container from "@material-ui/core/Container";
import Fade from "@material-ui/core/Fade";
import CircularProgress from "@material-ui/core/CircularProgress";
import SendIcon from "@material-ui/icons/Send";
import { ArrowBack } from "@material-ui/icons";
import { validi18nKey } from "../../utils";
import Checkbox from "@material-ui/core/Checkbox";
import FormHelperText from "@material-ui/core/FormHelperText";
import Link from "@material-ui/core/Link";
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Settings from "../../settings.yml";

validate.validators.checked = function (value, options) {
  if (value !== true)
    return options.message;
};

const schema = {
  customer_name: {
    presence: {
      allowEmpty: false,
      message: "^" + i18n.t("validation:field_required")
    },
    length: {
      maximum: 64
    }
  },

  nif: {
    presence: {
      allowEmpty: false,
      message: "^" + i18n.t("validation:field_required")
    },
    length: {
      maximum: 12
    }
  },
  contract: {
    presence: {
      allowEmpty: true,
      message: "^" + i18n.t("validation:field_required")
    },
    length: {
      maximum: 32
    }
  },
  cups: {
    presence: {
      allowEmpty: true,
      message: "^" + i18n.t("validation:field_required")
    },
    length: {
      maximum: 64
    }
  },
  email: {
    presence: {
      allowEmpty: false,
      message: "^" + i18n.t("validation:field_required")
    },
    email: true,
    length: {
      maximum: 128
    }
  },
  mobile: {
    presence: {
      allowEmpty: false,
      message: "^" + i18n.t("validation:field_required")
    },
    length: {
      maximum: 128
    }
  }
};
if (Settings.privacyLink) {
  schema['accept_conditions'] = {
    presence: { allowEmpty:  false, message: i18n.t("validation:field_required")},
    inclusion: {
      within: [true],
      message: "^" + i18n.t('validation:must_accept_conditions')
    }
  }
}

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.default,
  },
  content: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column'
  },
  contentBody: {
    flexGrow: 1,
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.down('md')]: {
      justifyContent: 'center'
    },
    flexDirection: "column",
  },
  form: {
    paddingLeft: 50,
    paddingRight: 50,
    paddingBottom: 65,
    flexBasis: 700,
    [theme.breakpoints.down('sm')]: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2)
    }
  },
  title: {
    marginTop: theme.spacing(4)
  },
  textField: {
    marginTop: theme.spacing(2)
  },
  accept_conditions: {
    marginTop: theme.spacing(1),
    display: 'flex',
    alignItems: 'center'
  },
  accept_conditionsCheckbox: {
    marginLeft: '-14px'
  },
  signUpButton: {
    margin: theme.spacing(2, 0)
  }
}));

const Signup = props => {

  const classes = useStyles();
  const {t} = useTranslation(['common', 'validation']);
  const userService = new UserService();

  const [formState, setFormState] = useState({
    isValid: false,
    values: {},
    touched: {},
    validationErrors: {},
    signUpErrors: {}
  });

  const [signedUp, setSignedUp] = useState(false)
  const [snackbarOpen, toggleSnackbar] = useState(false)
  const [snackbarInfo, showSnackbarInfo] = useState("")
  const [userEmail, setUserEmail] = useState("")
  const [signingUp, setSigningUp] = useState(false)
  const [checkedB, setCheckedB] = useState(false)
  const [checkedC, setCheckedC] = useState(false)

  useEffect(() => {
    let validation_schema;
    
    if(!checkedB){
      validation_schema = {
        ...schema,
        contract: {
          presence: false
        },
        cups: {
          presence: false
        }
      }
    } else {  
      validation_schema = schema;
    }

    let validationErrors = validate(formState.values, validation_schema);
    setFormState(formState => ({
      ...formState,
      isValid: !validationErrors,
      validationErrors: validationErrors || {}
    }));
    // eslint-disable-next-line
  }, [formState.values]);

  const handleChange = event => {
    event.persist();
    setFormState(formState => ({
      ...formState,
      values: {
        ...formState.values,
        [event.target.name]:
          event.target.type === 'checkbox'
            ? event.target.checked
            : event.target.value
      },
      touched: {
        ...formState.touched,
        [event.target.name]: true
      }
    }));
  };

  const handleContractBox = event => {
    setCheckedB(!checkedB);
    handleChange(event);
  }
  const handleContractOwnerBox = event => {
    setCheckedC(!checkedC);
    handleChange(event);
  }

  const handleSignUp = async event => {
    event.preventDefault();
    setSigningUp(true);
    let [statusCode, signUpErrors] = await userService.signUp(formState.values);
    if (200 <= statusCode && statusCode < 300) {
      setUserEmail(formState.values.email);
      setSignedUp(true);
    } else if (400 <= statusCode && statusCode < 500) {
      if (403 === statusCode) {
        toggleSnackbar(true);
        showSnackbarInfo(t('validation:forbidden_error'));
      } else {
        let errorKey = 'validation:incorrect_form';
        toggleSnackbar(true);
        showSnackbarInfo(t(errorKey));
      }
    } else if (500 === statusCode) {
      console.debug("INTERNAL SERVER ERROR")
      toggleSnackbar(true);
      showSnackbarInfo(t('validation:connection_error'));
    } else {
      console.debug("UNKNOWN ERROR");
      toggleSnackbar(true);
      showSnackbarInfo(t('validation:unknown_error'));
    }
    setFormState(formState => ({
      ...formState, signUpErrors
    }));
    setSigningUp(false);
  };

  const getError = field => {
    if (formState.touched[field]) {
      return (
        <React.Fragment>
          {
            formState.validationErrors[field] &&
            <p style={{margin: 0}}>{formState.validationErrors[field][0]}</p>
          }
          {
            formState.signUpErrors[field] &&
            <p style={{margin: 0}}>
              {
                i18n.exists("validation:" + validi18nKey(formState.signUpErrors[field][0])) ?
                  t("validation:" + validi18nKey(formState.signUpErrors[field][0]))
                  :
                  t("validation:not_valid_value")
              }
            </p>
          }
        </React.Fragment>
      )
    }
    return null;
  }

  const closeSnackbar = () => {
    toggleSnackbar(false)
  }

  const hasError = field => {
    return !!(formState.touched[field] && (formState.validationErrors[field] || formState.signUpErrors[field]));
  }

  const renderForm = () => {
    return (
      <form
        className={classes.form}
        onSubmit={handleSignUp}
      >
        <Typography
          className={classes.title}
          variant="h4"
        >
          {t('signup_title')}
        </Typography>
        <Typography
          color="textSecondary"
          gutterBottom
          style={{marginBottom: theme.spacing(3)}}
        >
          {t('signup_subtitle')}
        </Typography>
        <FormControlLabel
          control={
            <Checkbox
              checked={checkedB}
              onChange={handleContractBox}
              name="checkedB"
              color="primary"
            />
          }
          label={t('signup_already_contracted')}
        />
        { checkedB && 
          <FormControlLabel
            control={
              <Checkbox
                checked={checkedC}
                onChange={handleContractOwnerBox}
                name="checkedC"
                color="primary"
              />
            }
            label={t('signup_i_am_owner')}
          />
        }
        <TextField
          className={classes.textField}
          fullWidth
          label={t('full_name')}
          name="customer_name"
          FormHelperTextProps={{ component: "div" }}
          type="text"
          onChange={handleChange}
          value={formState.values.customer_name || ''}
          variant="outlined"
        />
        <TextField
          className={classes.textField}
          error={hasError('nif')}
          fullWidth
          helperText={
            hasError('nif') ? getError('nif') : null
          }
          label={t('nif')}
          name="nif"
          onChange={handleChange}
          FormHelperTextProps={{
            component: "div"
          }}
          type="text"
          value={formState.values.nif || ''}
          variant="outlined"
        />
        {checkedB &&
          <TextField
            className={classes.textField}
            error={hasError('contract')}
            fullWidth
            helperText={
              hasError('contract') ? getError('contract') : null
            }
            label={t('contract')}
            name="contract"
            onChange={handleChange}
            FormHelperTextProps={{
              component: "div"
            }}
            type="text"
            value={formState.values.contract || ''}
            variant="outlined"
          />
        }
        {checkedB &&
          <TextField
            className={classes.textField}
            error={hasError('cups')}
            fullWidth
            helperText={
              hasError('cups') ? getError('cups') : null
            }
            label={t('cups')}
            name="cups"
            onChange={handleChange}
            FormHelperTextProps={{
              component: "div"
            }}
            type="cups"
            value={formState.values.cups || ''}
            variant="outlined"
          />
        }
        <TextField
          className={classes.textField}
          error={hasError('email')}
          fullWidth
          helperText={
            hasError('email') ? getError('email') : null
          }
          label={t('email')}
          name="email"
          onChange={handleChange}
          FormHelperTextProps={{
            component: "div"
          }}
          type="text"
          value={formState.values.email || ''}
          variant="outlined"
        />
        <TextField
          className={classes.textField}
          error={hasError('mobile')}
          fullWidth
          helperText={
            hasError('mobile') ? getError('mobile') : null
          }
          label={t('mobile')}
          name="mobile"
          onChange={handleChange}
          FormHelperTextProps={{
            component: "div"
          }}
          type="text"
          value={formState.values.mobile || ''}
          variant="outlined"
        />
        {
          Settings.privacyLink && <div className={classes.accept_conditions}>
            <Checkbox
              checked={formState.values.accept_conditions || false}
              className={classes.accept_conditionsCheckbox}
              color="primary"
              name="accept_conditions"
              onChange={handleChange}
            />
            <Typography
              color="textSecondary"
              variant="body1"
            >
              {t('have_read_the') + '  '}
              <Link
                color="primary"
                href={Settings.privacyLink}
                underline="always"
                target="_blank"
                rel="noreferrer"
                variant="body1"
              >
                {t('common:accept_terms')}
              </Link>
            </Typography>
          </div>
        }
        {hasError('accept_conditions') && (
          <FormHelperText error>
            {getError('accept_conditions')}
          </FormHelperText>
        )}
        <Button
          className={classes.signUpButton}
          color="primary"
          disabled={!formState.isValid || signingUp}
          startIcon={signingUp ? <CircularProgress size={20}/> : <SendIcon/>}
          fullWidth
          size="large"
          type="submit"
          variant="contained"
        >
          {t('enable_user')}
        </Button>
        <Typography
          color="textSecondary"
          variant="body1"
        >
          {t('have_account_question') + '  '}
          <LinkRouter
            to="/acceso"
            variant="body1"
          >
            {t('access')}
          </LinkRouter>
        </Typography>
      </form>
    )
  }

  const renderSuccess = () => {
    return (
      <React.Fragment>
        <div style={{
          padding: theme.spacing(3)
        }}>
          <Typography
            style={{marginTop: theme.spacing(2)}}
            variant="h4"
          >
            {t('signup_success')}
          </Typography>
          <Typography variant="subtitle1" style={{marginTop: theme.spacing(4)}}>
            {t('signup_success_explanation')}
          </Typography>
          <Typography variant="h6" color="primary" style={{marginTop: theme.spacing(1)}}>
            {userEmail}
          </Typography>
          <Button
            className={classes.signUpButton}
            color="primary"
            style={{marginTop: theme.spacing(2)}}
            onClick={() => history.push('/')}
            startIcon={<ArrowBack/>}
            variant="contained"
          >
            {t('return_home')}
          </Button>
        </div>
      </React.Fragment>
    )
  }

  return (
    <Fade in={true}>
      <Container className={classes.root} style={{display: 'flex'}} maxWidth="md">
        <div className={classes.content}>
          <Card className={classes.contentBody} variant="outlined">
            {
              signedUp ?
                renderSuccess()
                :
                renderForm()
            }
          </Card>
        </div>
        <Snackbar open={snackbarOpen} autoHideDuration={1000}
                  onClose={closeSnackbar}>
          <Alert
            severity={'error'}
            onClose={closeSnackbar}>
            {snackbarInfo}
          </Alert>
        </Snackbar>
      </Container>
    </Fade>
  );
};

export default Signup;
